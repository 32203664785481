import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import styles from './About.module.css'

const redditMsg = <a href="https://www.reddit.com/message/compose/?to=Pewqazz" rel="noreferrer noopener" target="_blank">Reddit</a>
const twitter = <a href="https://twitter.com/iKevinY" rel="noreferrer noopener" target="_blank">Twitter</a>

const About = () => (
  <div className={ styles.about }>
    <Helmet><title>About | zeal.gg</title></Helmet>
    <div>
      <p>
        <strong>zeal.gg</strong> is a site that allows League of Legends players to create
        customized leaderboards to track the solo queue climb with their friends (or rivals).
        To create a leaderboard, input a list of summoners on the <Link to="/">home page</Link>;
        this will bring you to a page with a unique, shareable URL.
      </p>

      <p>
        Leaderboards can also be starred, which will add shortcuts to them to the home page.
      </p>

    </div>

    <div>
      <h3>Why did you make <strong>zeal.gg</strong>?</h3>

      <p>
        I&#39;m a software engineer who interned at Riot in 2018, and as you might imagine,
        a lot of the interns got <em>a little competitive</em> about grinding solo queue.
        I&#39;d find myself constantly refreshing a bunch of OP.GG tabs to keep an eye on how
        everyone was doing on the climb.
      </p>

      <p>
        This quickly became tedious, so I spent a weekend throwing together a prototype
        of a leaderboard that graphed everyone&#39;s rank. It turned out pretty cool, and I
        figured it would be something that the League community would like, so I fleshed out
        the design into a full site.
      </p>
    </div>

    <div>
      <h3>Why are leaderboards capped at 12 summoners?</h3>

      <p>
        With more than 12 summoners, the charts become a mess to read (and take too long to refresh).
      </p>
    </div>

    <div>
      <h3>Why do the charts only go back to a certain date?</h3>

      <p>
        Riot&#39;s API only allows you to check someone&#39;s current rank. In order to collect
        historical data, an explicit refresh needs to be performed from the site.
      </p>
    </div>

    <div>
      <h3>Why does <strong>zeal.gg</strong> not have <em>X</em>?</h3>

      <p>
        While there are a lot of features that would be nice to implement, my goal was to keep the
        site as minimalistic as possible. While I don&#39;t have much time to dedicate to development
        anymore, feedback is always welcome, preferably via { redditMsg } or { twitter }!
      </p>
    </div>
  </div>
)

export default About
