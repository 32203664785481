import React from 'react'
import styles from './App.module.css'
import { Link } from 'react-router-dom'

const Header = () => (
  <header className={ styles.header }>
    <h1 className={ styles.title }>
      <Link to="/">
        <img src="/zeal.svg" alt="zeal.gg Logo" className={ styles.logo } />
        zeal.gg
      </Link>
    </h1>
  </header>
)

export default Header
