import React from 'react'
import Leaderboard from './Leaderboard'

const Summoner = ({match}) => {
  const name = match.params.name
  const region = match.params.region

  return <Leaderboard match={{params: { name, region }}} />
}

export default Summoner
