import React, { Component } from 'react'
import PropTypes from 'prop-types'
import posthog from 'posthog-js'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faReddit, faMastodon } from '@fortawesome/free-brands-svg-icons'
import { Tooltip } from 'react-tippy'

import About from './About'
import Header from './Header'
import Landing from './Landing'
import Leaderboard from './Leaderboard'
import Summoner from './Summoner'

import styles from './App.module.css'

// eslint-disable-next-line no-extend-native
String.prototype.toTitleCase = function() {
  // Implementation via https://stackoverflow.com/a/196991
  return this.replace(/\w\S*/g, (s) => (
    s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
  ))
}

const Disclaimer = () => (
  <div className={ styles.disclaimer }>
    <strong>zeal.gg</strong> isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc.
  </div>
)

const FooterIcon = ({ icon, text, link, internal }) => {
  const faIcon = <FontAwesomeIcon icon={ icon } />

  let outLink
  if (internal != null) {
    outLink = <Link to={ internal }>{ faIcon }</Link>
  } else {
    outLink = <a href={ link } rel='noreferrer noopener' target='_blank'>{ faIcon }</a>
  }

  return (
    <Tooltip title={ text } arrow size="small" duration={ 100 } trigger="mouseenter">
      { outLink }
    </Tooltip>
  )
}

FooterIcon.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.string,
  internal: PropTypes.string,
}

const Footer = () => (
  <footer className={ styles.footer }>
    <FooterIcon icon={ faQuestionCircle } text='About/FAQ' internal="/about" />
    <FooterIcon icon={ faTwitter } text='@iKevinY' link='https://twitter.com/iKevinY' />
    <FooterIcon icon={ faMastodon } text='@iKevinY@mastodon.social' link='https://mastodon.social/@iKevinY' />
    <FooterIcon icon={ faReddit } text='/r/zealgg' link='https://reddit.com/r/zealgg' />
  </footer>
)

class App extends Component {
  render() {
    posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always',
    })

    return (
      <Router>
        <div className={ styles.app }>
          <Switch>
            <Route exact path="/" component={ Landing } />
            <Route component={ Header } />
          </Switch>

          <div className={ styles.content }>
            <Switch>
              <Route exact path="/" component={ null } />
              <Route path="/about" component={ About } />
              <Route path="/leaderboard/:slug" component={ Leaderboard } />
              <Route path="/:region/:name" component={ Summoner } />
              <Route path="/:slug" component={ Leaderboard } />
            </Switch>
          </div>

          <Switch>
            <Route path="/about" component={ Disclaimer } />
            <Route component={ Footer } />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
